import React, { memo } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import mapStyle from './MapStyle.json'

const containerStyle = {
  height: '100vh',
  width: 'auto',
  postion: 'relative'
}

const Map = props => {

  const onClick = (name, coords, details) => props.onMarkerClick(name, coords, details)

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyB7LkYZdeJ6TtE8LGuwdd2oihWdr02NHdM"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.center}
        zoom={props.zoom}
        options={{fullscreenControl: false, mapTypeControl: false, styles: mapStyle}}
      >
          { props.locations.map((loc) => ( 
              <Marker position={{ lat: parseFloat(loc.coords.split(',')[0]), lng: parseFloat(loc.coords.split(',')[1]) }} title={loc.name} onClick={() => { onClick(loc.name, loc.coords, loc.details)} } /> 
            )) 
          }
      </GoogleMap>
    </LoadScript>
  )
}

export default memo(Map)