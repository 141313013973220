import React, { useState } from 'react'
import './Auth.css'

const Auth = props => {

    const [ password, setPassword ] = useState('')

    const onClick = (e) => {
        e.preventDefault()
        if(password === 'urbex') {
            props.onLogIn(true)
        }
    }

    return (
        <div className="auth-wrapper">
            <div className="auth">
                <h1>Login</h1>
                <hr />
                <form>
                    <br />
                    <fieldset>
                        <input type="password" placeholder="Password" onChange={(e) => {setPassword(e.target.value)}} />
                    </fieldset>
                    <br />
                    <button onClick={onClick}>Login</button>
                </form>
            </div>
        </div>
    )
}

export default Auth
