import React from 'react'
import './Modal.css'
import { Modal } from 'react-responsive-modal';

const LocationModal = props => {
    return (
        <Modal 
          open={props.openModal} 
          onClose={props.onCloseModal} 
          classNames={{
              overlay: 'customOverlay', 
              modal: 'customModal'
            }} 
          center
        >
          <h1>{ props.currentLocationName }</h1>
          <hr />
          <p><a href={`http://maps.google.com/maps?q=loc:${props.currentLocationCoords}`} rel="noreferrer" target="_blank"><b><i>{ props.currentLocationCoords }</i></b></a></p>
          <p>{ props.currentLocationDetails }</p>
        </Modal>
    )
}

export default LocationModal
