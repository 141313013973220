import React from 'react'
import Card from '../Card'
import './ListView.css'

const ListView = props => {
    return (
        <div className="listView-wrapper">
          <div className="listView">
            <h1>Locations</h1>
            <hr />
            <div className="listView-scroll">
              { props.locations.map((loc) => (
                <Card name={loc.name} loc={loc.coords} coords={loc.coords} details={loc.details} onCardClick={props.onCardClick} onCardDbClick={props.onCardDbClick}/>
              )) }
            </div>
            <button className="listViewAdd-btn" onClick={() => {props.onAddLocation()}}>Add Location</button>
            <button className="listViewSearch-btn" onClick={() => {props.onSearch()}}>Search</button>
          </div>
      </div>
    )
}

export default ListView
