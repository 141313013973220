import React from 'react'
import './Modal.css'
import { Modal } from 'react-responsive-modal';

const AddLocationModal = props => {
    return (
        <Modal
          open={props.openAddLocationModal}
          onClose={props.onCloseLocationAddModal}
          classNames={{
            overlay: 'customOverlay', 
            modal: 'customModal'
          }} 
          center
        >
          <h1>Add a Location</h1>
            <hr />
            <form className="addLocationForm">
              <br />
              <fieldset>
                <input type="text" placeholder="Location Name" onChange={(e) => {props.setAddLocationName(e.target.value)}} />
                {props.locationNameError && <div class="error">{props.locationNameError}</div> }
              </fieldset>
              <br />
              <fieldset>
                <input type="text" placeholder="Location Coords" onChange={(e) => {props.setAddLocationCoords(e.target.value)}} />
                {props.locationCoordsError && <div class="error">{props.locationCoordsError}</div> }
              </fieldset>
              <br />
              <fieldset>
                <textarea type="text" placeholder="Location Details" onChange={(e) => {props.setAddLocationDetails(e.target.value)}} />
              </fieldset>
              <button className="addLocationBtn" onClick={props.onAddLocationBtnClick}>Add Location</button>
            </form>
        </Modal>
    )
}

export default AddLocationModal
