import React from 'react'
import './SearchModal.css'
import { Modal } from 'react-responsive-modal';

const SearchModal = props => {
    return (
        <Modal
            open={props.openSearchModal}
            onClose={props.onCloseSearchModal}
            classNames={{
                overlay: 'customOverlay', 
                modal: 'customModal'
            }} 
            center
        >
            <h1>Search</h1>
            <hr />
            <form className="addLocationForm">
                <br />
                <fieldset>
                <input type="text" placeholder="Location Name" value={props.query} onChange={(e) => {props.setQuery(e.target.value)}} />
                { props.searchError && <div class="error">{props.searchError}</div> }
                </fieldset>
                <br />
                <button className="addLocationBtn" onClick={props.onSearch}>Search</button>
                { props.query && <button className="clearSearchBtn" onClick={props.onClearSearch}>Clear Search</button> }
            </form>
        </Modal>
    )
}

export default SearchModal
