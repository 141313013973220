import './App.css';
import React, { useState, useEffect } from 'react'
import Map from './Components/Map'
import ListView from './Components/ListView'
import Auth from './Components/Auth'
import LocationModal from './Components/LocationModal'
import AddLocationModal from './Components/AddLocationModal'
import SearchModal from './Components/SearchModal'
import 'react-responsive-modal/styles.css';
import './Modal.css'
import { useMediaQuery } from 'react-responsive';

// db stuff
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";

initializeApp({
  apiKey: "AIzaSyBLAtKGvLWrd96bFx-r2DCd1H-Yv8QW3Yg",  
  authDomain: "lostandfound-4c24f.firebaseapp.com",
  projectId: "lostandfound-4c24f"
});

const db = getFirestore()

function App() {

  const storeBoolean = Boolean(localStorage.getItem("isLoggedIn"))
  const [ isLoggedIn, setIsLoggedIn ] = useState(
    storeBoolean ? true: false
  )
  const [ locations, setLocations ] = useState([])
  const [ center, setCenter ] = useState({lat: 35.97252996874817, lng: -79.9939205447525})
  const [ zoom, setZoom ] = useState(7)
  const [ openModal, setOpenModal ] = useState(false)
  const [ currentLocationName, setCurrentLocationName ] = useState('')
  const [ currentLocationCoords, setCurrentLocationCoords ] = useState('')
  const [ currentLocationDetails, setCurrentLocationDetails ] = useState('')
  const [ openAddLocationModal, setOpenAddLocationModal ] = useState(false)
  const [ addLocationName, setAddLocationName ] = useState('')
  const [ addLocationCoords, setAddLocationCoords ] = useState('')
  const [ addLocationDetails, setAddLocationDetails ] = useState('')
  const [ locationNameError, setLocationNameError ] = useState('')
  const [ locationCoordsError, setLocationCoordsError ] = useState('')
  const [ showListView, setShowListView ] = useState(false)
  const [ openSearchModal, setOpenSearchModal ] = useState(false)
  const [ query, setQuery ] = useState('')
  const [ searchError, setSearchError ] = useState('')
  const isMobile = useMediaQuery({ query: `(max-width: 950px)` })
  
  useEffect(() => {
    localStorage.setItem("isLoggedIn", Boolean(isLoggedIn))
    isLoggedIn && getLocations()
  }, [isLoggedIn])

  const getLocations = async () => {
    const qs = await getDocs(collection(db, 'Locations'))
    qs.forEach((doc) => {
      setLocations(loc => [...loc, doc.data()])
    })
  }

  const validCoords = /-?[0-9]+.[0-9]+,\s?-?[0-9]+.[0-9]+/
  const onOpenModal = () => setOpenModal(true)

  const onCloseModal = () => setOpenModal(false)

  const onOpenLocationAddModal = () => setOpenAddLocationModal(true)

  const onCloseLocationAddModal = () => setOpenAddLocationModal(false)

  const onCloseSearchModal = () => setOpenSearchModal(false)

  const onCardClick = loc => {
    setCenter({lat: parseFloat(loc.split(',')[0]), lng: parseFloat(loc.split(',')[1])})
    setZoom(17)
    toggleMobileBtn(false)
  }

  const onCardDbClick = (name, coords, details) => {
    setCurrentLocationName(name)
    setCurrentLocationCoords(coords)
    setCurrentLocationDetails(details)
    onOpenModal()
  }

  const onMarkerClick = (name, coords, details) => {
    setCurrentLocationName(name)
    setCurrentLocationCoords(coords)
    setCurrentLocationDetails(details)
    onOpenModal()
    return false;
  }

  const onAddLocationBtnClick = async e => {
    e.preventDefault()

    if(addLocationName === '') {
      setLocationNameError('Invalid location name entered!')
      return
    } 
    if(!validCoords.test(addLocationCoords)) {
      setLocationCoordsError('Invalid Coords entered!')
      return
    }

    try {
      const ref = await addDoc(collection(db, "Locations"), {
        name: addLocationName,
        coords: addLocationCoords,
        details: addLocationDetails
      })
    } catch(e) {
      console.log('Error:', e)
    }
    setAddLocationName('')
    setAddLocationCoords('')
    setAddLocationDetails('')
    window.location.reload(false);
  }

  const onAddLocation = () => {
    onOpenLocationAddModal()
  }

  const toggleMobileBtn = () => {
    setShowListView(!showListView)
  }

  const onSearchBtnClick = () => {
    setOpenSearchModal(true)
  }

  const onSearch = async e => {
    e.preventDefault()
    if(query === '') {
      setSearchError('Invalid query')
      return
    }
    else {
      setSearchError('')
    }
    setLocations(locations.filter(loc => loc.name.toLowerCase().includes(query.toLowerCase())))
    setOpenSearchModal(false)
  }

  const onClearSearch = async () => {
    setQuery('')
    const ref = await getLocations()
  }

  return (
    <>
    { !isLoggedIn ? (
      <div className="App">
          <Auth onLogIn={setIsLoggedIn} />
      </div>
    ) : (
      <div className="App">
        <Map 
          center={center} 
          locations={locations} 
          zoom={zoom} 
          onMarkerClick={onMarkerClick} 
        />
        {
          showListView || !isMobile ? 
            <ListView 
              locations={locations} 
              onAddLocation={onAddLocation}
              onCardClick={onCardClick} 
              onCardDbClick={onCardDbClick} 
              onSearch={onSearchBtnClick}
            /> 
          : 
            <></>
        }
        <LocationModal
          openModal={openModal}
          onCloseModal={onCloseModal}
          currentLocationName={currentLocationName}
          currentLocationCoords={currentLocationCoords}
          currentLocationDetails={currentLocationDetails}
        />
        <AddLocationModal 
          openAddLocationModal={openAddLocationModal}
          onCloseLocationAddModal={onCloseLocationAddModal}
          setAddLocationName={setAddLocationName}
          setAddLocationCoords={setAddLocationCoords}
          setAddLocationDetails={setAddLocationDetails}
          locationNameError={locationNameError}
          locationCoordsError={locationCoordsError}
          onAddLocationBtnClick={onAddLocationBtnClick}
        />
        <SearchModal 
          openSearchModal={openSearchModal}
          onCloseSearchModal={onCloseSearchModal}
          searchError={searchError}
          onSearch={onSearch}
          query={query}
          onClearSearch={onClearSearch}
          setQuery={setQuery}
        />
        <button className="listViewBtn" onClick={toggleMobileBtn}><b>List View</b></button>
      </div>
      )}
    </>
  );
}

export default App;
