import React from 'react'
import './Card.css'

const index = props => {

    const onClick = () => {
        props.onCardClick(props.loc)
    }

    const onDoubleClick = () => {
        props.onCardDbClick(props.name, props.coords, props.details)
    }

    return (
        <div className="card" onClick={ onClick } onDoubleClick={ onDoubleClick }>
            <h4>{ props.name }</h4>
        </div>
    )
}

export default index
